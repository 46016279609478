import "../../pages/order-form/OrderForm.css";
import Table from "react-bootstrap/Table";
import { getOrderInvoiceById } from "@app/services/order";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import CurrencyFormat from 'react-currency-format';
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import jsPDF from "jspdf";

const ViewInvoice = () => {
	const [cartInvoice, setCartInvoice] = useState([]);
	const [payableAmt, setPayableAmt] = useState('0');
	const [netAmt, setNetAmt] = useState('0');
	const [vatAmt, setVatAmt] = useState('0');
    let { id } = useParams();
	const pdfRef = useRef(null);

	useEffect(() => {
		getOrderInvoice(id);
	}, [])

	const getOrderInvoice = async (orderId) => {
        try {
            // setInvoiceLoading(true);
            const resp = await getOrderInvoiceById(orderId);
            if (resp?.success === true) {
				// setInvoiceLoading(false);
				setCartInvoice(resp?.collections);
				setPayableAmt(resp?.collections?.total?.payable)
				setNetAmt(resp?.collections?.total?.net)
				setVatAmt(resp?.collections?.total?.vat)
            } else {
				// setInvoiceLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            // setInvoiceLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const exportToPDF = () => {
		let doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});
		doc.html(pdfRef.current, {
			callback: function (doc) {
				doc.save('Invoice-FCL0000' + id);
			},
			x: 25,
			y: 25,
			html2canvas: {
				scale: 0.42,
				windowWidth: 350
			}
		});
	}

	return (
		<div className="col-md-12">
			<section className="content">
				<div className="container-fluid table_container">
					<Table responsive className="invoice_wrapper" ref={pdfRef}>
						<thead>
							<tr>
								<th>
									<b>INVOICE</b>
								</th>
								<th>
									<b>Order ID: </b>  #{ cartInvoice['order']?.id }
								</th>
								<th></th>
								<th colSpan={3}>
									<b>Order Date: </b> { moment(cartInvoice['order']?.date).format('DD-MM-YYYY hh:mm A') }
								</th>
							</tr>
						</thead>
						<tbody style={{wordSpacing: '3px'}}>
							<tr>
								<td colSpan={6} style={{ height: "20px", padding: "0" }}>
									&nbsp;
								</td>
							</tr>
							<tr>
								<td rowSpan={4}>
									<img
										src="../../../img/FCL-logo.png"
										alt="Logo"
										style={{ maxWidth: "150px" }}
									/>
								</td>
								<td
									colSpan={1}
									style={{
										border: "1px solid #000000",
										borderBottom: "0px",
										wordSpacing: '3px'
									}}
								>
									<b>First Class Learning Ltd</b>
								</td>
								<td>&nbsp;</td>
								<td colSpan={2}
									style={{
										borderTop: "1px solid #000000",
										borderLeft: "1px solid #000000",
										borderRight: "1px solid #000000",
									}}
								><b>{ cartInvoice['franchise']?.name }</b></td>
							</tr>
							<tr>
								<td rowSpan={1} colSpan={1} 
									style={{
										borderLeft: "1px solid #000000",
										borderRight: "1px solid #000000",
										wordSpacing: '3px'
									}}
								>	4th Floor The Blade, Abbey Street, Reading, RG1 3BE, United Kingdom
								</td>
								<td>&nbsp;</td>
								<td rowSpan={1} colSpan={2}
									style={{
										borderLeft: "1px solid #000000",
										borderRight: "1px solid #000000",
									}}
								>{ cartInvoice['order']?.address ?? '---'} <br /> </td>
							</tr>
							<tr>
								<td 
									style={{
										borderBottom: "1px solid #000000",
										borderLeft: "1px solid #000000",
										borderRight: "1px solid #000000",
									}}
								><b>VAT Reg. Number :</b> 103791431</td>
								<td>&nbsp;</td>
								<td
									style={{
										borderBottom: "1px solid #000000",
										borderLeft: "1px solid #000000",
										borderRight: "1px solid #000000",
									}}
								><b>Order Account :</b> { cartInvoice['franchise']?.kashflow_order_account ?? '---'}</td>
							</tr>
							<tr>
								<td colSpan={5} style={{ padding: "30px 10px", color: "red", wordSpacing: '4px' }}>
									{/* <b>
										Free processing on orders of £100 and over (excluding VAT ) in your first year.
									</b> */}
								</td>
							</tr>
							<tr>
								<td colSpan={6} style={{ padding: "0" }}>
									<Table className="invoice_inner_wrapper">
										<thead>
											<tr>
												<th style={{borderLeft: '1px solid #000000'}}>
													<b>Qty</b>
												</th>
												<th>
													<b>Description</b>
												</th>
												<th>
													<b>Unit Cost</b>
												</th>
												<th>
													<b>VAT %</b>
												</th>
												<th>
													<b>Net</b>
												</th>
												<th>
													<b>VAT</b>
												</th>
											</tr>
										</thead>
										<tbody>
											{ cartInvoice['sets'] && (
												<>
													{(cartInvoice['sets']).map((set,i) => (
													<tr key={i}>
														<td>{ set?.qty }</td>
														<td>{ set?.subject } - { set?.description }</td>
														<td><CurrencyFormat value={ parseFloat(set?.unit_cost).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
														<td>{ parseFloat(set?.vat_percent).toFixed(2) } %</td>
														<td><CurrencyFormat value={ parseFloat(set?.net).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
														<td><CurrencyFormat value={ parseFloat(set?.vat_amount).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
													</tr>
													))}
												</>
											)}
											 
											{ cartInvoice['handbooks'] && (
												<tr>
													<td>{ cartInvoice['handbooks']['qty'] }</td>
													<td>{ cartInvoice['handbooks']['description'] }</td>
													<td><CurrencyFormat value={ parseFloat(cartInvoice['handbooks']['unit_cost']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
													<td>{ parseFloat(cartInvoice['handbooks']['vat_percent']).toFixed(2) } %</td>
													<td><CurrencyFormat value={ parseFloat(cartInvoice['handbooks']['net']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
													<td><CurrencyFormat value={ parseFloat(cartInvoice['handbooks']['vat_amount']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
												</tr>
											)}
											
											{ cartInvoice['handbook_suppliments'] && (
												<tr>
													<td>{ cartInvoice['handbook_suppliments']['qty'] }</td>
													<td>{ cartInvoice['handbook_suppliments']['description'] }</td>
													<td><CurrencyFormat value={ parseFloat(cartInvoice['handbook_suppliments']['unit_cost']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
													<td>{ parseFloat(cartInvoice['handbook_suppliments']['vat_percent']).toFixed(2) } %</td>
													<td><CurrencyFormat value={ parseFloat(cartInvoice['handbook_suppliments']['net']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
													<td><CurrencyFormat value={ parseFloat(cartInvoice['handbook_suppliments']['vat_amount']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
												</tr>
											)}
											
											{ (cartInvoice['other_items']) && (
												<>
												{(cartInvoice['other_items']).map((item,i) => (
													<tr key={i}>
														<td>{ item?.qty }</td>
														<td>{ item?.description }</td>
														<td><CurrencyFormat value={ parseFloat(item?.unit_cost).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
														<td>{ parseFloat(item?.vat_percent).toFixed(2) } %</td>
														<td><CurrencyFormat value={ parseFloat(item?.net).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
														<td><CurrencyFormat value={ parseFloat(item?.vat_amount).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
													</tr>
												))}
												</>
											)}
											
											{ cartInvoice['processing_charge_standard'] && (
												<tr>
													<td>{ cartInvoice['processing_charge_standard']['qty'] }</td>
													<td>{ cartInvoice['processing_charge_standard']['description'] }</td>
													<td><CurrencyFormat value={ parseFloat(cartInvoice['processing_charge_standard']['unit_cost']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
													<td>{ parseFloat(cartInvoice['processing_charge_standard']['vat_percent']).toFixed(2) } %</td>
													<td><CurrencyFormat value={ parseFloat(cartInvoice['processing_charge_standard']['net']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
													<td><CurrencyFormat value={ parseFloat(cartInvoice['processing_charge_standard']['vat_amount']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
												</tr>
											)}
											
											{ cartInvoice['processing_charge_first_year'] && (
												<tr>
													<td>{ cartInvoice['processing_charge_first_year']['qty'] }</td>
													<td>{ cartInvoice['processing_charge_first_year']['description'] }</td>
													<td><CurrencyFormat value={ parseFloat(cartInvoice['processing_charge_first_year']['unit_cost']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
													<td>{ parseFloat(cartInvoice['processing_charge_first_year']['vat_percent']).toFixed(2) } %</td>
													<td><CurrencyFormat value={ parseFloat(cartInvoice['processing_charge_first_year']['net']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
													<td><CurrencyFormat value={ parseFloat(cartInvoice['processing_charge_first_year']['vat_amount']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></td>
												</tr>
											)}
										</tbody>
									</Table>
								</td>
							</tr>
							<tr>
								<td colSpan={6} style={{ padding: "0" }}>
									<Table className="invoice_inner_wrapper total_table mb-0">
										<thead>
											<tr>
												<th>
													<div className="free-processing">
														<h6>Free processing -</h6>
														<span>1. In your first year on orders over £100 (excluding VAT )</span><br/>
														<span>2. On all orders over £250 (excluding VAT ) </span> 
													</div>
												</th>
												<th>
													<Table className="invoice_inner_wrapper total_table mb-0">
														<thead>
															<tr>
																<th style={{textAlign: "left", wordSpacing: '4px'}}>
																	{/* <b>Free processing -</b> */}
																</th>
																<th>
																	<b>Net :</b>
																</th>
																<th>
																	<CurrencyFormat value={ netAmt ? parseFloat(netAmt).toFixed(2) : '--' } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} />
																</th>
															</tr>
															<tr>
																<th style={{textAlign: "left", wordSpacing: '4px' }}>
																	{/* <b>1. In your first year on orders over £100 (excluding VAT )</b> */}
																</th>
																<th>
																	<b>VAT :</b>
																</th>
																<th>
																	<CurrencyFormat value={ vatAmt ? parseFloat(vatAmt).toFixed(2) : '--' } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} />
																</th>
															</tr>
															<tr>
																<th style={{ textAlign: "left", wordSpacing: '4px'}}>
																	{/* <b>2. On all orders over £250 (excluding VAT ) </b>  */}
																</th>
																<th className="total_payble_title" style={{ backgroundColor: 'unset', wordSpacing: '7px' }}>
																	<b>TOTAL PAYABLE :</b>
																</th>
																<th className="total_payble_amt" style={{ backgroundColor: 'unset' }}>
																	<b><CurrencyFormat value={ payableAmt ? parseFloat(payableAmt).toFixed(2) : '--' } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></b>
																</th>
															</tr>
														</thead>
													</Table>
												</th>
											</tr>
											
										</thead>
									</Table>
								</td>
							</tr>
						</tbody>
					</Table>
					<div className="card-footer">
						<a className="btn btn-primary mr-2" onClick={exportToPDF} >Download PDF</a>
						<Link className="btn btn-primary mr-2" to={`/orders/view/${id}`}>View Order Details</Link>
						<Link className="btn btn-default" to='/in-progress-orders'>Close</Link>
					</div>
					<div className="mt-4 d-flex justify-content-end align-items-center">
						{/* <span>
							<b>Total Sets:240</b>
						</span> */}
						
					</div>
				</div>
			</section>
		</div>
	);
};

export default ViewInvoice;